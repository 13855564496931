<template>
  <div class="admin-tollfree-detail">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loading">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="brandForm">
                <form @submit.prevent="handleSubmit(onUpdateClick)">
                  <div class="d-flex justify-content-between">
                    <h5 class="h5">Number Verification Detail #{{ data.id }}</h5>
                    <b-button variant="warning" @click="onBack">
                      <span><i class="uil uil-arrow-left"></i> Back</span>
                    </b-button>
                  </div>
                  <div class="d-block">
                    <span v-if="isVerified" class="badge bg-success text-white"><i class="uil uil-check-circle"></i>
                      {{ statusLabel | capitalize }}</span>
                    <span v-else-if="isRejected" class="badge bg-danger text-white"><i class="uil uil-times"></i>
                      {{ statusLabel | capitalize }}</span>
                    <span v-else-if="!data.status" class="badge bg-dark text-white"><i class="uil uil-upload"></i>
                      Draft</span>
                    <span v-else class="badge text-white bg-warning">
                      <i class="uil uil-refresh"></i> {{
                        statusLabel | capitalize }}</span>
                    <span v-if="!verification.legal_name" class="d-block my-3 font-weight-bold text-danger">We can't show
                      information below because this tollfree request is submitted directly out of our system.</span>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6 mb-3">
                      <TextInput v-model="verification.useCase" name="useCase" label="UseCase" disabled />
                      <span class="help-block">{{ verification.useCaseSummary }}</span>
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <PhoneInput v-model="phoneSelected" name="number" label="Phone Number" sublabel="*"
                        rules="required|phone" :disabled="true" />
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider v-slot="{ failed, errors }" rules="required" name="message_volume">
                        <label>Estimated Monthly Message Volume <span class="text-danger">*</span></label>
                        <multiselect v-model="verification.message_volume" :allow-empty="false" :options="messageVolumes"
                          :multiple="false" :class="{ 'is-invalid': failed }" track-by="value" label="label"
                          :show-labels="false" :disabled="formDisabled">
                        </multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Opt-In WorkFlow <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name="optInWorkflow">
                          <textarea v-model="verification.optInWorkflow.description" name="optInWorkflow"
                            class="form-control" rows="5" :class="{ 'is-invalid': failed }"
                            :disabled="formDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="imageUrlSelected" name="optinImgUrl" label="URL of OptIn Image" sublabel="*"
                        rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Production Message <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name="productionMessage">
                          <textarea v-model="verification.productionMessageContent" name="productionMessage"
                            class="form-control" rows="3" :class="{ 'is-invalid': failed }"
                            :disabled="formDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="h5">Sender Information</h5>
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.legal_name" name="legalCompanyName" label="Legal Company Name"
                        sublabel="*" rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.address1" name="address" label="Address" sublabel="*"
                        rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.address2" name="address2" label="Address2"
                        :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.city" name="city" label="City" sublabel="*" rules="required"
                        :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <!-- <ValidationProvider v-slot="{ failed, errors }" name="State/Province" rules="required"
                        :disabled="formDisabled">
                        <label>State/Province <span class="text-danger">*</span></label>
                        <multiselect v-model="verification.state" :allow-empty="true" :options="states" :multiple="false"
                          :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                          :disabled="formDisabled"></multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider> -->
                      <TextInput v-model="verification.state" name="State" rules="required" label="State" sublabel="*"
                    :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.zip" name="zip" label="Zip" rules="required" sublabel="*"
                        :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.website" name="website" label="Website" rules="required"
                        sublabel="*" :disabled="formDisabled" />
                    </div>
                  </div>
                  <div v-if="verification.businessContact" class="row">
                    <div class="col-md-12">
                      <h5 class="h5">Business Contact Information</h5>
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.businessContact.firstName" name="fullName" label="Full Name"
                        sublabel="*" rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.businessContact.lastName" name="lastName" label="Last Name"
                        sublabel="*" rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="verification.businessContact.email" name="email" label="Email" sublabel="*"
                        rules="required" :disabled="formDisabled" />
                    </div>
                    <div class="col-md-4">
                      <PhoneInput v-model="verification.businessContact.phoneNumber" name="phoneNumber"
                        label="PhoneNumber" sublabel="*" rules="required|phone" :disabled="formDisabled" />
                    </div>
                  </div>
                  <div class="col-md-12 text-center mt-3">
                    <b-form-group>
                      <b-button variant="warning" @click="onBack">
                        <span><i class="uil uil-arrow-left"></i> Back</span>
                      </b-button>
                      <b-button variant="primary" :disabled="processing || (invalid && touched) || formDisabled"
                        type="submit" class="ml-2">
                        <b-spinner v-if="processing" small />
                        <span v-else>Update</span>
                      </b-button>
                    </b-form-group>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showConfirmModal" no-close-on-backdrop hide-header-close>
      <template v-slot:modal-title>
        <h5 class="text-primary">Confirmation</h5>
      </template>
      <p>Are you sure information you put below is accurate?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showConfirmModal = false">Cancel</b-button>
        <b-button variant="primary" @click="onUpdate">
          <b-spinner v-if="processing" small />
          <span v-else>Update</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
    
<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  data() {
    return {
      loading: false,
      processing: false,
      messageVolumes: [
        { value: "10", label: "10" },
        { value: "100", label: "100" },
        { value: "1000", label: "1,000" },
        { value: "10000", label: "10,000" },
        { value: "100000", label: "100,000" },
        { value: "250000", label: "250,000" },
        { value: "500000", label: "500,000" },
        { value: "750000", label: "750,000" },
        { value: "1000000", label: "1,000,000" },
        { value: "5000000", label: "5,000,000" },
        { value: "10000000", label: "10,000,000" },
      ],
      data: {},
      verification: {
        optInWorkflow: {},
        businessContact: {},
      },
      phoneSelected: null,
      imageUrlSelected: null,
      showConfirmModal: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    ...mapGetters('business', {
      states: 'statesBw',
    }),

    formDisabled() {
      return this.isVerified
    },

    status() {
      return this.data && this.data.main_status
    },

    isVerified() {
      if (!this.status)
        return false
      return ['verified', 'resolved - verified'].includes(this.status.toLowerCase())
    },

    isRejected() {
      if (!this.status)
        return false
      return ['unverified', 'invalid_status', 'resolved - not verified'].includes(this.status.toLowerCase())
    },

    declinedReason() {
      return this.data && this.data.reason_declined
    },

    statusLabel() {
      let res = 'Draft';
      if (this.isVerified) {
        res = 'Verified & Approved';
      } else if (this.isRejected) {
        res = 'Not Approved';
      } else if (this.status) {
        res = this.status;
      }
      return res
    },
  },

  mounted() {
    let id = this.$route.params.id
    if (id) {
      this.getRequestDetail(id)
    } else {
      this.$router.push({ name: 'admin.settings.tollfree' })
    }
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'admin.settings.tollfree' })
    },

    initData() {
      if (!this.verification.optInWorkflow)
        this.verification.optInWorkflow = {}
      if (!this.verification.businessAddress)
        this.verification.businessAddress = {}
      this.imageUrlSelected = this.verification.optInWorkflow.imageUrls ? this.verification.optInWorkflow.imageUrls[0] : null
      this.phoneSelected = this.verification.phoneNumbers ? this.verification.phoneNumbers[0] : null
    },

    getRequestDetail(id) {
      this.loading = true
      this.$store
        .dispatch('admin/getTollfreeRequest', id)
        .then((data) => {
          this.data = data.data
          if (this.data.verification) {
            this.verification = this.data.verification
            this.initData()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$router.push({ name: 'admin.settings.tollfree' })
        })
    },

    onUpdateClick() {
      this.showConfirmModal = true;
    },

    onUpdate() {
      this.showConfirmModal = false
      this.processing = true

      this.verification.optInWorkflow.imageUrls = [this.imageUrlSelected]
      this.verification.phoneNumbers = [this.phoneSelected]
      let params = {
        ...this.verification,
        id: this.data.id,
      }

      this.$store
        .dispatch('admin/updateTollfreeRequest', params)
        .then((data) => {
          this.processing = false
          this.getRequestDetail(this.data.id)
        })
        .catch(() => {
          this.processing = false
          this.getRequestDetail(this.data.id)
        })
    },
  },
}
</script>
    
<style lang="scss" scoped>
.admin-tollfree-detail {
  .radio-wrapper {
    >label {
      display: inline-block;
      width: 625px;
    }

    .radio-group {
      display: inline-block;
    }
  }
}
</style>